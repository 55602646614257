<template>
  <div class="app-tree">
    <div class="tree-message position-relative rounded bg-light m-2 p-3" :class="`ml-${depth}`">
      <div class="d-flex align-items-center pb-1">
        <!--begin::Info-->
        <div class="d-flex flex-column flex-grow-1 mb-3">
          <div class="d-flex text-dark-75 justify-content-between mb-1 font-size-lg">
            <span class="font-weight-bolder">{{ item.creator.label }}</span>
            <span class="reply-text text-muted text-hover-primary cursor-pointer" @click.self="handleClick(item)">
              {{ $t('label.reply') }}
            </span>
          </div>
          <span class="text-muted font-weight-bold font-size-sm">{{ item.created_at }}</span>
        </div>
        <!--end::Info-->
      </div>
      <div class="d-flex justify-content-between">
        <p class="text-dark-75 font-size-lg font-weight-normal mb-0">
          {{ item.text }}
        </p>
        <div v-if="user.id === item.creator.id && false" class="test">
          <span class="reply-text svg-icon svg-icon-primary cursor-pointer mr-1" @click="updateComment">
            <inline-svg src="/media/svg/icons/General/Edit.svg"/>
          </span>
          <span class="reply-text svg-icon svg-icon-danger cursor-pointer" @click="deleteComment">
            <inline-svg src="/media/svg/icons/General/Trash.svg"/>
          </span>
        </div>
      </div>
    </div>

    <div v-if="item.children && item.children.length">
      <discussion-tree v-for="child in item.children" :key="child.id" :item="child" :depth="depth + 15" @onReply="handleClick(child)"/>
    </div>
  </div>
</template>

<script>
import LocalStorageService from '@/core/services/local-storage.service';

export default {
  name: 'discussion-tree',
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    depth: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    user: null
  }),
  beforeMount() {
    this.user = LocalStorageService.getUser();
  },
  methods: {
    handleClick(item) {
      this.$emit('onReply', item);
    },
    updateComment() {
      console.log('update');
    },
    deleteComment() {
      console.log('delete');
    }
  }
}
</script>

<style lang="scss" scoped>
.tree-message {
  &:hover {
    .reply-text {
      display: inline-block;
    }
  }

  .reply-text {
    display: none;
  }
}
</style>