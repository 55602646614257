<template>
  <app-card :card-class="form.parent_id ? 'pb-35' : 'pb-20'">
    <template #title>{{ $t('label.discussion') }}</template>
    <template #body>
      <div v-if="comments && comments.length" class="thread-wrapper">
        <discussion-tree v-for="comment in comments" :key="comment.id" :idx="comment.id" :item="comment" @onReply="onReply"/>
      </div>
      <div v-else class="h-100px">
        <div class="d-flex justify-content-center align-items-center h-100 text-muted font-size-h5">
          {{ $t('label.no_comments_yet') }}
        </div>
      </div>

      <div class="comments-reply-wrapper position-absolute w-100 bg-white px-3">
        <div v-if="form.parent_id" class="d-flex justify-content-between parent-comment pt-5 pb-3 pl-2">
          <span>{{ parentText }}</span>
          <inline-svg
              src="/media/svg/icons/Navigation/Close.svg"
              class="cursor-pointer"
              @click="form.parent_id = null"
          />
        </div>
        <div class="separator separator-solid mt-2 mb-4"></div>
        <div class="d-flex justify-content-between align-items-center">
          <textarea v-model="form.text"
                    class="form-control border-0 p-0 pr-10 resize-none"
                    rows="3"
                    :placeholder="form.parent_id ? $t('placeholder.reply') : $t('placeholder.write_comment')"
          />
          <b-button size="sm" variant="primary" :disabled="!form.text" @click="submit">{{ $t('btn.send') }}</b-button>
        </div>
      </div>
    </template>
  </app-card>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';
import DiscussionTree from '@/components/comments/DiscussionTree';

export default {
  components: {
    AppCard,
    DiscussionTree
  },
  props: {
    entity: {
      type: String,
      default: null
    },
    entityId: {
      default: null
    }
  },
  data: () => ({
    form: {
      parent_id: null,
      text: ''
    },
    parentText: null
  }),
  beforeMount() {
    this.getData();
  },
  computed: {
    ...mapGetters({
      comments: 'commentsStore/COMMENTS'
    })
  },
  methods: {
    onReply(item) {
      this.form.parent_id = item.id;
      this.parentText = item.text;
    },
    getData() {
      this.$store.dispatch('commentsStore/GET_THREAD', {entity: this.entity, entityId: this.entityId});
    },
    async submit() {
      await this.$store.dispatch('commentsStore/CREATE', {
        entity: this.entity,
        entityId: this.entityId,
        dataToSubmit: this.form
      });
      this.clearForm();
      this.getData();
    },
    clearForm() {
      this.form.parent_id = null;
      this.form.text = '';
    },
  },
  beforeDestroy() {
    this.$store.commit('commentsStore/CLEAR_COMMENTS');
  }
}
</script>

<style lang="scss" scoped>
.comments-reply-wrapper {
  left: 0;
  bottom: 15px;
}
</style>